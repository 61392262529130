<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages" />
    <div class="flex flex-1 items-center justify-center" v-if="isLoading">Loading...</div>
    <div class="p-6" v-if="!isLoading">
      <div class="w-full h-full ">
        <form class="card p-6 bg-gray-100" @submit="handleSubmit($event)">
          <div class="w-full">
            <div class="mb-2 w-full mr-2">
              <label class="block mb-2 text-sm font-bold" for="survey_content">
                Email
              </label>
              <input
                class="py-2 px-3 field w-full border"
                type="email"
                required
                name="email"
                autocomplete="nofill"
                v-model="formData.email"
              />
            </div>
            <div class="mb-2 mr-2 w-full">
              <label class="block mb-2 text-sm font-bold" for="survey_content">
                Password
              </label>
              <input
                class="py-2 px-3 field w-full border"
                type="password"
                required
                name="password"
                autocomplete="nofill"
                v-model="formData.password"
              />
            </div>
          </div>

          <div class="w-full">
            <div class="mb-2 w-full mr-2">
              <label class="block mb-2 text-sm font-bold" for="district">
                District
              </label>
              <SearchDropdown
                :placeholder="`Please Select District`"
                :value="formData.district"
                :options="getGroupsValues.district"
                @selected="value => (formData.district = value)"
              />
            </div>

            <div class="mb-2 w-full mr-2">
              <label class="block mb-2 text-sm font-bold" for="division">
                Divisional Secretariat
              </label>

              <SearchDropdown
                :key="formData.district"
                :placeholder="`Please Select Divisional Secretariat`"
                :value="formData.division"
                :options="getDivisionalData"
                @selected="value => (formData.division = value)"
              />
            </div>
            <div class="mb-2 w-full mr-2">
              <label class="block mb-2 text-sm font-bold" for="gn">
                GN Division
              </label>
              <SearchDropdown
                :key="formData.division"
                :placeholder="`Please Select GN Division`"
                :value="formData.gn"
                :options="getGnData"
                @selected="value => (formData.gn = value)"
              />
            </div>
          </div>
          <div class="flex justify-end">
            <button
              type="submit"
              :disabled="isSubmitting"
              class="focus:outline-none focus:shadow-outline text-white bg-green-800 px-4 py-2"
            >
              {{ isSubmitting ? 'Submitting' : 'Add User' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {API, Auth} from 'aws-amplify';
import SearchDropdown from '../../components/SearchDropdown';
import {groups} from './filters';
import {filters} from '../Surveys/filters';
import {useToast} from 'vue-toastification';
import {graphqlOperation} from '@aws-amplify/api';
import {createUser} from '../../graphql/mutations';
import {v4 as uuidv4} from 'uuid';

export default {
  components: {
    SearchDropdown,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        district: null,
        division: null,
        gn: null,
        email: null,
        password: null,
      },
      isSubmitting: false,
    };
  },
  computed: {
    pages() {
      return [
        {
          name: 'Users',
          to: {name: 'users'},
          current: false,
        },
        {
          name: 'Add New User',
          to: {name: 'users.create'},
          current: true,
        },
      ];
    },
    getGroupsValues() {
      return groups;
    },
    getFilters() {
      return filters;
    },
    getDivisionalData() {
      const items = groups.ds;
      if (this.formData.district) {
        return items.filter(item => item.district === this.formData.district.name);
      }
      return items;
    },
    getGnData() {
      const items = groups.gn;
      if (this.formData.division) {
        console.log(
          items.filter(item => item.ds === this.formData.division.name),
          '1212',
        );
        return items.filter(item => item.ds === this.formData.division.name);
      }
      return items;
    },
  },
  methods: {
    async handleSubmit($event) {
      const toast = useToast();
      $event.preventDefault();
      this.isSubmitting = true;
      try {
        const apiName = 'AdminQueries';
        const path = '/addUser';

        const userInit = {
          body: {
            username: this.formData.email,
            fullName: this.formData.email,
            password: this.formData.password,
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const response = await API.post(apiName, path, userInit);
        console.log(response, 'response');
        await API.graphql(
          graphqlOperation(createUser, {
            input: {
              id: uuidv4(),
              username: this.formData.email,
              email: this.formData.email,
              owner: response?.data?.result?.User?.Attributes?.filter(item => item.Name === 'sub')
                ?.Value,
            },
          }),
        );
        const groupPath = '/addUserToGroup';

        let groupName = null;

        if (this.formData.district && this.formData.district.value) {
          groupName = this.formData.district.value;
        }
        if (this.formData.division && this.formData.division.value) {
          groupName = this.formData.division.value;
        }
        if (this.formData.gn && this.formData.gn.value) {
          groupName = this.formData.gn.value;
        }
        if (groupName) {
          const userGroupInit = {
            body: {
              groupname: groupName,
              username: this.formData.email,
              fullName: this.formData.email,
            },
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            },
          };
          await API.post(apiName, groupPath, userGroupInit);
          toast.success('User Created Successfully', {
            timeout: 5000,
          });
          await this.$router.push({
            name: 'users',
          });
        }
      } catch (e) {
        console.log(e, 'e');
      }
      this.isSubmitting = false;
    },
  },
};
</script>
